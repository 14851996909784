import React from "react";
import { Input, Button,Form, message } from "antd";

import { httpGet, httpPost} from '../../http';

const FormItem = Form.Item;

class FormContainer extends React.Component {
  state = { 
    clinicInfo: {
      name: "name",
      address:  "address"
    }
  };
  getClinicInfo = () => {
    httpGet("/api/v1/hospital/get_hospital_info")
    .then(res => {
      return res.json();
    })
    .then(data => {
      this.setState({
        clinicInfo: data.data,
      })
    })
    .catch(err => {
      message.error('Interface error:get_hospital_info');
    }
    )
  }
  componentDidMount() {
    this.getClinicInfo()
  }
  render() {
    const {form} = this.props;

    const handleOk = () => {
      form.validateFields((err, fieldsValue) => {
        if (err) return;
        form.resetFields();
        let submitValue = {
          ...fieldsValue,
        };
        httpPost("/api/v1/hospital/set_hospital_info", JSON.stringify(submitValue))
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (data.code === 0) {
              this.getClinicInfo()
            } else {
              message.error(data.msg);
            }
          })
          .catch((err) => {
            message.error("Interface error: set_hostital_info");
          });
      });
    };
    
    return (
      
        <Form
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          layout="horizontal"
          // {...formItemLayout}
          hideRequiredMark
        >
          <FormItem label="Name">
            {form.getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Please input the clinic name.",
                },
              ],
              initialValue: this.state.clinicInfo.name,
            })(<Input />)}
          </FormItem>
          
          <FormItem label="Address">
            {form.getFieldDecorator("address", {
              rules: [
                {
                  required: true,
                  message: "Please input the clinic address.",
                },
              ],
              initialValue: this.state.clinicInfo.address ,
            })(<Input />)}
          </FormItem>
            <Button type="primary" onClick={() => handleOk()}>
              Submit
            </Button>
        </Form>
    );
  }
}

const ClinicForm = Form.create()(FormContainer);
export default ClinicForm;
