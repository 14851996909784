import React, {Component} from 'react';
import {Route, Redirect } from 'react-router-dom';

class AuthRouter extends Component{
    render(){
        const { component: Component, ...rest } = this.props
        const isLogged = sessionStorage.getItem("isLogin") === "1" ? true : false
        return(
            <Route {...rest} render={ data => {
              return isLogged? <Component {...data} />:<Redirect to="/login" />
            }} />
        )
    }
}

export default AuthRouter