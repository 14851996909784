import React from 'react';
import { httpPost } from '../../http';
import { message, Modal, Input, Form, Select } from 'antd';

const FormItem = Form.Item;
const { Option } = Select;


class AddFormContainer extends React.Component {
  state = {};
  render() {
    const { modalVisibleAdd, form, modalTitleAdd, handleModalVisibleAdd, getUserList, initData } = this.props;
    const handleOk = () => {
      form.validateFields((err, fieldsValue) => {
        if (err) return;
        form.resetFields();
        let submitValue = {
          user_id: initData.user_id,
          ...fieldsValue
        };
        if (!initData.user_id) {
          httpPost("/api/v1/user/add_user", JSON.stringify(submitValue))
            .then(res => {
              return res.json();
            })
            .then(data => {
              if (data.code === 0) {
                handleModalVisibleAdd();
                getUserList();
              } else {
                message.error(data.msg);
              }
            })
            .catch(err => {
              message.error('Interface error:add_user');
            }
            )
        } else {
          httpPost("/api/v1/user/update_user", JSON.stringify(submitValue))
            .then(res => {
              return res.json();
            })
            .then(data => {
              if (data.code === 0) {
                handleModalVisibleAdd();
                getUserList();
              } else {
                message.error(data.msg);
              }
            })
            .catch(err => {
              message.error('Interface error:update_user');
            }
            )
        }
      });
    };
    const formateRole = (role) => {
      if (role) {
        return role === 'nurse' ? 1 : role === 'doctor' ? 2 : 3
      } else{
        return ''
      }
    }
    return (
      <Modal
        destroyOnClose
        title={modalTitleAdd}
        visible={modalVisibleAdd}
        width={400}
        okText="Save"
        onOk={handleOk}
        onCancel={() => handleModalVisibleAdd()}

      >
        <Form layout="vertical" hideRequiredMark>
          <FormItem label='Name'>
            {form.getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Please input Name!',
                },
              ],
              initialValue: initData.name,
            })(<Input disabled={initData.user_id ? true : false} />)}
          </FormItem>
          <FormItem label='Role'>
            {form.getFieldDecorator('role_id', {
              rules: [
                {
                  required: true,
                  message: 'Please Select Role!',
                },
              ],
              initialValue: formateRole(initData.role),
            })(
              <Select>
                <Option value={1} >nurse</Option>
                <Option value={2} >doctor</Option>
                <Option value={3} >admin</Option>
              </Select>
            )}
          </FormItem>

          <FormItem label='Phone'>
            {form.getFieldDecorator('phone', {
              rules: [
                {
                  required: true,
                  message: 'Please input Phone!',
                },
              ],
              initialValue: initData.phone,
            })(<Input />)}
          </FormItem>
        </Form>
      </Modal>
    )
  }
}

const AddForm = Form.create()(AddFormContainer);
export default AddForm;