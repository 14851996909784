import React from "react";
import io from 'socket.io-client'

import { Table, Button, message } from "antd";

import { httpGet } from "../../http";
import View from "./View";

import "./pharmacist.css";

export default class Pharmacist extends React.Component {
  state = {
    modalVisible: false,
    editValue: {},
    data: [],
    socket: undefined
  };

  getMedicineQueue = () => {
    const domain = document.domain
    let socket = ''
    if (domain === 'localhost') {
      socket = io("ws://192.168.0.130:9090")
    } else {
      socket = io("wss://" + document.location.host)
    }

    socket.on("TakeMedicineQueue", (data) => {
      if (data === "True") {
        this.getPatientsList()
      }
    })
    this.setState({
      socket: socket
    })
  }
  componentDidMount() {
    this.getPatientsList()
    setTimeout(() => {
      this.getMedicineQueue()
    })
  }

  componentWillUnmount() {
    this.state.socket && this.state.socket !== '' && this.state.socket.close()
  }

  getPatientsList = () => {
    httpGet("/api/v1/patient/need_take_pharmacist_patients_list")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          data: data.data,
        });
      })
      .catch((err) => {
        message.error("Interface error:get_patients_list");
      });
  };

  getPatientInfo = (id, flag) => {
    httpGet("/api/v1/patient/get_patient_info?id=" + id)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          editValue: data.data,
          modalVisible: !!flag,
        });
      })
      .catch((err) => {
        message.error("Interface error: get_patient_info");
      });
  };
  handleModalVisible = (flag, record) => {
    if (record !== undefined) {
       this.getPatientInfo(record.id, flag);
    } else {
      this.setState({
        modalVisible: !!flag,
        editValue: {},
      });
    }
  };
  columns = [
    {
      title: "Patient",
      dataIndex: "name",
      key: "name",
      align: 'center'
    },
    {
      title: "NRIC",
      dataIndex: "nric",
      key: "nric",
      align: 'center'
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
      align: 'center'
    },
    {
      title: "Gender",
      key: "gender",
      align: 'center',
      render: (text, record) => (
        <span>
          {record.gender === undefined ? "" : record.gender === 0 ? "Male" : "Female"}
        </span>
      )
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      align: 'center'
    },
    {
      title: "Comment",
      dataIndex: "description",
      key: "description",
      align: 'center'
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <Button
            type="primary"
            onClick={() => this.handleModalVisible(true, record)}
          >
            View
          </Button>
        </span>
      ),
    },
  ];

  render() {
    const parentMethods = {
      handleModalVisible: this.handleModalVisible,
      getPatientsList: this.getPatientsList,
    };
    return (
      <div className="pharmacist" key="pharmacist">
        <Table
          rowKey={(record, index) => index}
          columns={this.columns}
          dataSource={this.state.data}
          // showHeader={false}
          pagination={false}
        />

        <View
          {...parentMethods}
          modalVisible={this.state.modalVisible}
          initData={this.state.editValue}
        />
        
      </div>
    );
  }
}
