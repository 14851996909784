import React, {Component} from 'react';

import { Progress } from 'antd';

class Cabinet extends Component {
  render () {
    const {medicine, box_number,  current, total, ratio, unit, low_level, normal_level} = this.props;
    // const strokeColor = ratio > 20 ? (ratio > 80 ? 'green' : 'blue') : 'red'
    
    let domRatio = undefined
    if (ratio < low_level || ratio === low_level) {
      domRatio = <Progress type="circle" percent={ratio} width={80} strokeColor='rgb(255,0,0)'/>
    } else if ( ratio > low_level && ratio < normal_level) {
      domRatio = <Progress type="circle" percent={ratio} width={80} strokeColor='rgb(255,153,0)'/>
    } else {
      domRatio = <Progress type="circle" percent={ratio} width={80} strokeColor='rgb(47,85,151)' format={percent => percent + '%'}/>
    }

    return (
      <div className='cabinet'>
        {domRatio}
        <div className='number fontSize16'>{box_number}</div>
        <div className='fontSize18'>{medicine === '' ? '***' : medicine}</div>
        <div className='fontSize18'>{current}/{total}</div>
        <div >{unit === '' ? '***' : unit}</div>
      </div>
    )
  }
}

export default Cabinet