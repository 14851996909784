import React, { Component } from 'react'
import AuthRouter from 'components/Router/AuthRouter.js'
import Outline from 'components/Outline'
import Home from "components/Home"
import Doctor from 'components/Doctor'
import Pharmacist from 'components/Pharmacist'
import Setting from 'components/Setting'
import Supplier from 'components/Supplier'
import Medicine from 'components/Medicine'
import Alarm from 'components/Alarm'
import Exception from 'components/Exception'
import User from 'components/User'
import Update from 'components/Update'
import Calibration3 from 'components/Calibration3'
import { Switch } from 'react-router'
import NotFound from "components/404"

class SubRouter extends Component {
  render() {
    const role = sessionStorage.getItem("role");
    let block=<div></div>;

    switch (role) {
      case "nurse":
        block = (
        <Switch>
          <AuthRouter exact path='/' component={Home}></AuthRouter>
          <AuthRouter path='/home' component={Home}></AuthRouter>
          <AuthRouter path='/pharmacist' component={Pharmacist}></AuthRouter>
          <AuthRouter path='/exception' component={Exception}></AuthRouter>

          <AuthRouter path="*" component={NotFound}></AuthRouter>
        </Switch>
        )
        break;
      case "doctor":
        block =  (
        <Switch>
          <AuthRouter exact path='/' component={Doctor}></AuthRouter>
          <AuthRouter path='/doctor' component={Doctor}></AuthRouter>

          <AuthRouter path="*" component={NotFound}></AuthRouter>
        </Switch>
        )
        break;
      case "admin":
        block =  (
        <Switch>
          <AuthRouter exact path='/' component={Home}></AuthRouter>
          <AuthRouter path='/home' component={Home}></AuthRouter>
          <AuthRouter path='/setting' component={Setting}></AuthRouter>
          <AuthRouter path='/supplier' component={Supplier}></AuthRouter>
          <AuthRouter path='/medicine' component={Medicine}></AuthRouter>
          <AuthRouter path='/alarm' component={Alarm}></AuthRouter>
          <AuthRouter path='/exception' component={Exception}></AuthRouter>
          <AuthRouter path='/user' component={User}></AuthRouter>
          <AuthRouter path='/update' component={Update}></AuthRouter>
          <AuthRouter path='/calibration' component={Calibration3}></AuthRouter>

          <AuthRouter path="*" component={NotFound}></AuthRouter>
        </Switch>
        )
        break;
      default:
        block = (
          <AuthRouter path="*" component={NotFound}></AuthRouter>
        )
    }

    return (
      <Outline>
        {block}
      </Outline>
    );
  }
}


export default SubRouter