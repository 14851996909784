// get
export function httpGet(url) {
  const token = sessionStorage.getItem("token");
  var result = fetch(url, {
    method: "get",
    headers: {
      'token': token 
    }
  });
  return result;
}


// post
export function httpPost(url, data) {
  const token = sessionStorage.getItem("token");
  var result = fetch(url, {
    method: "post",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token': token
    },
    body: data
  })
  return result;
}

// delete
export function httpDelete(url, data) {
  var result = fetch(url, {
    method: "delete",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: data
  })
  return result;
}

// function params(obj){
// 	  var result = ""; 
// 	  var item;
// 	  for(item in obj){
// 	    result += "&"+item+"="+encodeURIComponent(obj[item]);
// 	  }
// 	  if(result){
// 	    result = result.slice(1)
// 	  }
// 	  return result;
// }
