import React from "react";

import { Select, Row, Col, Descriptions, Table, Button, message, Input, Icon } from "antd";

import { httpGet, httpPost } from "../../http";
import "./doctor.css";

const { Option } = Select;
const { TextArea } = Input;

export default class Doctor extends React.Component {
  state = {
    selectedPatient: {},
    patientData: [],
    medicineData: [],
    data: [
      {
        medicine: undefined,
        num: 1,
      },
      {
        medicine: undefined,
        num: 1,
      },
      {
        medicine: undefined,
        num: 1,
      },
      {
        medicine: undefined,
        num: 1,
      },
      {
        medicine: undefined,
        num: 1,
      },
    ],
    comment: ""
  };

  componentDidMount() {
    this.getPatientsList();
    this.getAllMedicines();
  }

  componentWillUnmount() {}

  getPatientsList = () => {
    httpGet("/api/v1/patient/get_patients_list")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          patientData: data.data,
        });
      })
      .catch((err) => {
        message.error("Interface error:get_patients_list");
      });
  };
  getPatientInfo = (id) => {
    httpGet("/api/v1/patient/get_patient_info?id=" + id)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          selectedPatient: data.data,
        });
      })
      .catch((err) => {
        message.error("Interface error: get_patient_info");
      });
  };

  getAllMedicines = () => {
    httpGet("/api/v1/medicine/get_medicine_in_box")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          medicineData: data.data,
        });
      })
      .catch((err) => {
        message.error("Interface error: get_medicines_in_box");
      });
  };

  updatePatientInfo = (item) => {
    httpPost("/api/v1/patient/update_patient_info", JSON.stringify(item))
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.code === 0) {
          message.success("Submitted successfully.")
          setTimeout(() => {
            window.location.reload()
          }, 500)
        } else {
          message.error(data.msg);
        }
      })
      .catch((err) => {
        message.error("Interface error:update_tag");
      });
  };

  onPatientChange = (id) => {
    console.log(id);
    // const selected =  this.state.patientData.filter((item) => item.name === value)
    // this.setState({
    //   selectedPatient: selected[0]
    // })
    this.getPatientInfo(id);
  };

  onMedicineChange = (value, option, index) => {
    let newData = [...this.state.data];
    newData[index].medicine = value;

    if (value === undefined) {
      newData[index].num = 1;
    }

    this.setState({
      data: newData,
    });
  };

  onIncrease = (index) => {
    let newData = [...this.state.data];
    newData[index].num++;

    this.setState({
      data: newData,
    });
  };

  onDecrease = (index) => {
    let newData = [...this.state.data];
    if (newData[index].num > 1) {
      newData[index].num--;

      this.setState({
        data: newData,
      });
    }
  };

  addMedicine = () => {
    let newData = [...this.state.data];
    newData.push({
      medicine: undefined,
      num: 1,
    });
    this.setState({
      data: newData,
    });
  };
  onTextAreaChange = (e) => {
    this.setState({
      comment: e.target.value
    })
  }
  submit = () => {
    // 数据校验/提示/刷新页面
    const prescription = this.state.data.filter(
      (item) => item.medicine !== undefined
    );
    let medicine_list = {};

    for (let item of prescription) {
      // let obj = {
      //   [item.medicine]: item.num,
      // };

      medicine_list[item.medicine] = item.num;
    }
    const submitData = {
      patient_id: this.state.selectedPatient.id,
      medicine_list: medicine_list,
      description: this.state.comment
    };
    if (submitData.patient_id === undefined) {
      message.error("Please select a patient")
    } else if (submitData.medicine_list.length === 0) {
      message.error("Please select at least one medicine")
    }
    else {
      this.updatePatientInfo(submitData)
    }
  };
  columns = [
    {
      title: "NO.",
      dataIndex: "Num",
      render: (text, record, index) => index + 1,
    },
    {
      title: "medicine",
      dataIndex: "medicine",
      align: 'center',
      render: (text, record, index) => (
        <Select
          defaultValue={record.medicine}
          showSearch
          style={{ width: 200 }}
          placeholder="Select a medicine"
          optionFilterProp="children"
          showArrow={false}
          allowClear={true}
          onChange={(...arg) => this.onMedicineChange(...arg, index)}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {this.state.medicineData.map((item, index) => {
            return (
              <Option key={index + item.medicine} value={item.medicine}>
                {item.medicine}
              </Option>
            );
          })}
        </Select>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => (
        <span>
          <Button
            type="primary"
            className="fontSize16"
            onClick={() => this.onDecrease(index)}
          >
            -1
          </Button>
          <Button disabled style={{ color: "#000" }} className="fontSize16">
            {record.num}
          </Button>
          <Button
            type="primary"
            className="fontSize16"
            onClick={() => this.onIncrease(index)}
          >
            +1
          </Button>
        </span>
      ),
    },
  ];
  render() {
    const { age, gender, birthday, phone, address,nric } =
      this.state.selectedPatient;
    return (
      <div className="doctor" key="doctor">
        <Row>
          <Col span={10} >
            <div className="patient">
              <Select
                style={{ width: 500, fontSize: 16 }}
                placeholder="Select a patient"
                onChange={this.onPatientChange}
              >
                {this.state.patientData.map((item) => {
                  return (
                    <Option key={item.id + item.name} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
              <br />
              <br />
              <Descriptions title="Info" column={1}>
                <Descriptions.Item label="NRIC">{nric}</Descriptions.Item>
                <Descriptions.Item label="Age">{age}</Descriptions.Item>
                <Descriptions.Item label="Gender">
                  {gender === undefined ? "" : gender === 0 ? "Male" : "Female"}
                </Descriptions.Item>
                <Descriptions.Item label="Birthday">
                  {birthday}
                </Descriptions.Item>
                <Descriptions.Item label="Telephone">{phone}</Descriptions.Item>
                <Descriptions.Item label="Address">{address}</Descriptions.Item>
              </Descriptions>
            </div>
          </Col>
          <Col span={14} >
            <div className="prescription">
              
              <Descriptions title="Prescription"></Descriptions>
              <Table
                rowKey={(record, index) => index}
                columns={this.columns}
                dataSource={this.state.data}
                showHeader={false}
                pagination={false}
                size="small"
              />
              <div style={{ marginTop: 10, textAlign: "center" }}>
                <Button
                  onClick={this.addMedicine}
                  className="addMedicine"
                  type="dash"
                >
                  <Icon type="plus" /> Add field
                </Button>
              </div>
              
              <Descriptions title="Comment" style={{marginTop: 20}}></Descriptions>
              <TextArea rows={4} onChange={this.onTextAreaChange}/>
              
            </div>
            
          </Col>
          
        </Row>
        <div style={{ marginTop: 20, textAlign: "center" }}>
          <Button type="primary" size="large" onClick={this.submit}>
            Submit
          </Button>
        </div>
      </div>
    );
  }
}
