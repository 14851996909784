import React from 'react';

import { Upload, message, Button, Icon, Descriptions, Popconfirm, Table, Modal, Spin, Divider} from 'antd';

import { httpGet, httpPost } from '../../http';
import './update.css'

export default class Update extends React.Component {
  state = {
    visible: false,
    current: undefined,
    total: undefined,
    info: '',
    data: [
      
    ]
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
      current: undefined,
      total: undefined
    });
  };
  
  getFirmwareInfo = () => {
    httpGet("/api/v1/cabinet/firmware/firmware_info")
    .then(res => {
      return res.json();
    })
    .then(data => {
      this.setState({
        info: data.data,
      })
    })
    .catch(err => {
      message.error('Interface error: get_firmware_info');
    }
    )
  }

  getVersionList = () => {
    httpGet("/api/v1/cabinet/firmware/version_list")
    .then(res => {
      return res.json();
    })
    .then(data => {
      this.setState({
        data: data.data,
      })
    })
    .catch(err => {
      message.error('Interface error: get_version_list');
    }
    )
  }
  


  checkTaskID = (id, type) => {
    httpGet("/api/v1/cabinet/result/" + id)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.code === 0) {  
          
          if ( type === "check") {
            const _data = data.data
            if (_data.state === "PROGRESS") {
              this.setState({
                current: _data.current,
                total: _data.total
              })
            }else if (_data.state === "SUCCESS") {     
              clearInterval(this.state.interval);
              this.setState({
                data: _data.version_info
              })
              this.hideModal()
            }

          } 
          if ( type === "checkByID") {
            const _data = data.data
            if (_data.state === "SUCCESS") {     
              clearInterval(this.state.interval);
              //  _data.data = [{"box_number":1,"version":"v2.7.20220804.bin"}]
              const result = _data.version_info[0]
              this.formateVersionByID(result.box_number, result.version)
              this.hideModal()
            }
          }
          
          if ( type === "update") {
            const _data = data.data
            if (_data.state === "SUCCESS") {     
              clearInterval(this.state.interval);
              this.hideModal()
            }
          }
        } 
        return
      })
      .catch((err) => {
        message.error("Interface error: result task", err);
      });
  }

  // check all version
  checkVersion = () => {
    httpPost("/api/v1/cabinet/firmware/check_version")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.code === 0) {

          // 开启遮罩
          this.showModal()

          // 异步查询任务
          console.log("taskID", data.data)
          const interval = setInterval(() => {
            this.checkTaskID(data.data, "check")
          }, 5 * 1000)

          this.setState({
            interval: interval
          })

          message.success("Check Version");
        } else {
          message.error(data.msg);
        }
      })
      .catch((err) => {
        message.error("Interface error: check_version");
    });
  }
  
  formateVersionByID = (box_number, version) => {
    const newData = this.state.data
    newData.forEach(item => {
      if (item.box_number === box_number) {
        item.version = version
      }
    })
    this.setState({
      data: newData
    })
  }

  // check version by id
  checkVersionByID = (box_number) => {
    let submitValue = {
      box_number: box_number
     };
    httpPost("/api/v1/cabinet/firmware/check_version", JSON.stringify(submitValue))
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.code === 0) {

          // 开启遮罩
          this.showModal()

          // 异步查询任务
          console.log("taskID", data.data)
          const interval = setInterval(() => {
            this.checkTaskID(data.data, "checkByID")
          }, 5 * 1000)

          this.setState({
            interval: interval
          })

          message.success("Check Version");
        } else {
          message.error(data.msg);
        }
      })
      .catch((err) => {
        message.error("Interface error: check_version");
    });
  }
  
  // update all box
  updateFirmware = () => {
     let submitValue = {};
    httpPost("/api/v1/cabinet/firmware/update", JSON.stringify(submitValue))
    .then(res => {
      return res.json();
    })
    .then(data => {
      if (data.code === 0) {

        // 开启遮罩
        this.showModal()

        // 异步查询任务
        console.log("taskID", data.data)
        const interval = setInterval(() => {
          this.checkTaskID(data.data, "update")
        }, 5 * 1000)

        this.setState({
          interval: interval
        })


        message.success(`${this.state.info} update`);
      } 
    })
    .catch(err => {
      message.error('Interface error: update firmware');
    }
    )
  }

  // update box by id
  updateFirmwareByID = (box_number) => {
     let submitValue = {
      box_number: box_number
     };
    httpPost("/api/v1/cabinet/firmware/update", JSON.stringify(submitValue))
    .then(res => {
      return res.json();
    })
    .then(data => {
      if (data.code === 0) {
        // 开启遮罩
        this.showModal()

        // 异步查询任务
        console.log("taskID", data.data)
        const interval = setInterval(() => {
          this.checkTaskID(data.data, "update")
        }, 5 * 1000)

        this.setState({
          interval: interval
        })

        message.success(`${box_number} update`);
        this.getFirmwareInfo()
      } 
    })
    .catch(err => {
      message.error('Interface error: update firmware by box_number');
    }
    )
  }

  updateInfo = (info) => {
    this.setState({
      info: info
    })
  }
  componentDidMount() {
    this.getFirmwareInfo()
    this.getVersionList()
  }

  componentWillUnmount() {
    if (this.state.interval) clearInterval(this.state.interval)
  }

  columns = [
    {
      title: "Box number",
      dataIndex: "box_number",
      key: "box_number",
      align: 'center'
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      align: 'center'
    },
    {
      title: "Action",
      key: 'action',
      align: 'center',
      render: (text, record) => (
      <span>
        <Popconfirm placement="top" title="Are you sure you want to update this box?"
          onConfirm={() => this.updateFirmwareByID(record.box_number)} okText="Yes" cancelText="No" >
          <a href="#!;">Update</a>
        </Popconfirm>
        <Divider type="vertical" /> 
        <Popconfirm placement="top" title="Are you sure you want to check this box's version?"
          onConfirm={() => this.checkVersionByID(record.box_number)} okText="Yes" cancelText="No" >
          <a href="#!;">Check</a>
        </Popconfirm>
      </span>
      ),
    },
  ]  
 
  render() {
   
    const props = {
      name: 'file',
      action: '/api/v1/cabinet/firmware/upload_file',
      headers: {
        authorization: 'authorization-text',
      },
      showUploadList: false,
      onChange: (info) => {
        if (info.file.status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
          this.setState({
            info: info.file.name
          })
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };
    return (
      <div className='update'>
        <div className='updateBox'>
          <Descriptions title="">
            <Descriptions.Item label="Current Version">
              {this.state.info}
            </Descriptions.Item>
          </Descriptions>
          <Upload {...props}>
            <Button>
              <Icon type="upload" /> Click to Upload
            </Button>
          </Upload>
          <Button className='updateBtn' type="danger" onClick={() => this.updateFirmware()}>Update All</Button>
          <Button className='checkBtn' type="danger" onClick={() => {this.checkVersion()}}>Check All</Button>

          <br />
          <br />
          <hr />

          <Table
            rowKey={(record, index) => index}
            columns={this.columns}
            dataSource={this.state.data}
            pagination={false}
          />
        </div>

        <Modal
          style={{marginTop: "180px"}}
          title={null}
          visible={this.state.visible} 
          footer={null}
          closable={false}    
        >
          <div style={{textAlign: "center"}}>
            <Spin size="large"/>
            <div style={{fontSize: "20px"}}>
              {this.state.current && this.state.current} { this.state.total > 1 ? "/" : "" } {this.state.total && this.state.total}
            </div>
          </div>
        </Modal>
      </div>
    );
  };
 
};
