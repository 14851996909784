import React from 'react';

import { Button, message } from 'antd';

import { httpGet } from '../../http';
import AddForm from './addForm';
import ClinicForm from './clinicForm';

import './setting.css';

export default class Setting extends React.Component {
  state = {
    modalVisibleAdd: false,
    modalTitleAdd: "",
    editValues: {},
    medicineData: [],
    data: []
  }

  handleModalVisibleAdd = (flag, title, record) => {
    this.setState({
      modalVisibleAdd: !!flag,
      modalTitleAdd: title,
      editValues: record || {},
    });
  };
  
  getBoxsInfo = () => {
    httpGet("/api/v1/cabinet/get_boxs_info")
    .then(res => {
      return res.json();
    })
    .then(data => {
      this.setState({
        data: data.data,
      })
    })
    .catch(err => {
      message.error('Interface error:get_boxs_info');
    }
    )
  }
  getAllMedicines = () => {
    httpGet("/api/v1/medicine/get_all_medicines")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          medicineData: data.data,
        });
      })
      .catch((err) => {
        message.error("Interface error: get_all_medicines");
      });
  };
  componentDidMount() {
    this.getBoxsInfo()
    this.getAllMedicines()
  }

  componentWillUnmount() {
  }

  render() {
    const parentMethodsAdd = {
      handleModalVisibleAdd: this.handleModalVisibleAdd,
      getBoxsInfo: this.getBoxsInfo
    };
   
    return (
      <div className='setting' key="setting">
        <div className='wrapCabinet'>
          {this.state.data.map( (item, index) => {
            return (
              <div className='cabinet' key={index}>
                <div className='fontSize16'>NO. {item.box_number}</div>
                <div className='fontSize18'>{item.medicine === '' ? '***' : item.medicine}</div>
                <div className='fontSize16'>{item.total}</div>
                <div className='fontSize16'>{item.unit === '' ? '***' : item.unit}</div>
                <div style={{textAlign: 'right'}}>
                  <Button type='primary' shape="circle" icon="setting" style={{fontSize: 18}} 
                    onClick={() => this.handleModalVisibleAdd(true, 'Edit Cabinet', item)}
                  />
                </div>
              </div>
            )
          })}
        </div>
        <hr />
        <div className="clinicInfo" style={{ margin: '0 auto', marginTop: 20,width: 600,textAlign: 'center'}}>
          <ClinicForm />
        </div>
        <AddForm {...parentMethodsAdd} modalVisibleAdd={this.state.modalVisibleAdd} modalTitleAdd={this.state.modalTitleAdd}
          medicineData={this.state.medicineData} initData={this.state.editValues} />
      </div>
    );
  };
 
};
