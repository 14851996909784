import React from "react";
import { Modal, Input, InputNumber, Select,Form, message } from "antd";

import { httpGet, httpPost} from '../../http';

const FormItem = Form.Item;
const { Option } = Select;

class AddFormContainer extends React.Component {
  state = {
    data: [],
    supplierData: [
      "supplier1",
      "supplier2",
      "supplier3",
    ]
  };

  getSupplierList = () => {
    httpGet("/api/v1/supplier/get_suppliers_info")
    .then(res => {
      return res.json();
    })
    .then(data => {
      this.setState({
        supplierData: data.data,
      })
    })
    .catch(err => {
      message.error('Interface error: get_supplier_list');
    }
    )
  }

  componentDidMount() {
    this.getSupplierList()
  }
  render() {
    const {
      modalVisibleAdd,
      form,
      modalTitleAdd,
      handleModalVisibleAdd,
      getMedicineList,
      initData,
    } = this.props;
    
    const handleOk = () => {
      form.validateFields((err, fieldsValue) => {
        if (err) return;
        form.resetFields();    

        let submitValue = {
          id: initData.id,
          ...fieldsValue
        };
        if (!initData.id) {
          httpPost("/api/v1/medicine/add_medicine", JSON.stringify(submitValue))
            .then(res => {
              return res.json();
            })
            .then(data => {
              if (data.code === 0) {
                handleModalVisibleAdd();
                getMedicineList();
              } else {
                message.error(data.msg);
              }
            })
            .catch(err => {
              message.error('Interface error:add_medicine');
            }
            )
        } else {
          httpPost("/api/v1/medicine/set_medicine_info", JSON.stringify(submitValue))
            .then(res => {
              return res.json();
            })
            .then(data => {
              if (data.code === 0) {
                handleModalVisibleAdd();
                getMedicineList();
              } else {
                message.error(data.msg);
              }
            })
            .catch(err => {
              message.error('Interface error:update_medicine');
            }
            )
        }
      });
    };
    return (
      <Modal
        destroyOnClose
        title={modalTitleAdd}
        visible={modalVisibleAdd}
        width={600}
        okText="Save"
        onOk={handleOk}
        onCancel={() => handleModalVisibleAdd()}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
          // {...formItemLayout}
          hideRequiredMark
        >
          <FormItem label="Name">
            {form.getFieldDecorator("medicine", {
              rules: [
                {
                  required: true,
                  message: "Please input name.",
                },
              ],
              initialValue: initData.medicine,
            })(<Input />)}
          </FormItem>
          <FormItem label="Alert Num">
            {form.getFieldDecorator("alert_num", {
              rules: [
                {
                  required: true,
                  message: "Please input alert num.",
                },
              ],
              initialValue: initData.alert_num,
            })(<InputNumber />)}
          
          </FormItem>
          <FormItem label="Store Num">
            {form.getFieldDecorator("store_num", {
              rules: [
                {
                  required: true,
                  message: "Please input store num.",
                },
              ],
              initialValue: initData.store_num,
            })(<InputNumber />)}
          
          </FormItem>
          <FormItem label="Default Order Num">
            {form.getFieldDecorator("default_order_num", {
              rules: [
                {
                  required: true,
                  message: "Please input default order num.",
                },
              ],
              initialValue: initData.default_order_num,
            })(<InputNumber />)}
          </FormItem>
          <FormItem label="Supplier">
            {form.getFieldDecorator("supplier_id", {
              rules: [
                {
                  required: true,
                  message: "Please select supplier.",
                },
              ],
              initialValue: initData.supplier_id,
            })(
              <Select>
                {this.state.supplierData.map((item) => (
                  <Option key={item && item.name + Math.random()} value={item && item.id}>
                    {item && item.name}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
          <FormItem label="Description">
            {form.getFieldDecorator("description", {
              rules: [
                {
                  required: true,
                  message: "Please input description.",
                },
              ],
              initialValue: initData.description,
            })(<Input />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const AddForm = Form.create()(AddFormContainer);
export default AddForm;
