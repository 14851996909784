import React from "react";
import { Modal, InputNumber, Button,Form, message, Row, Col } from "antd";

import { httpPost} from '../../http';

const FormItem = Form.Item;

class AddFormContainer extends React.Component {
  state = {
    data: [],
    closable: true,
    interval: null
  };

  init = () => {
    this.setState({
      closable: true,
      interval: null
    });
  };

  render() {
    const {
      modalVisibleAdd,
      form,
      modalTitleAdd,
      handleModalVisibleAdd,
      initData,
    } = this.props;

    const handlePeel = () => {
      let submitValue = {
        box_number: initData.box_number
      };
      httpPost("/api/v1/cabinet/scale_peeling", JSON.stringify(submitValue))
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.code === 0) {
            message.success(`Peel Success`)
            
            handleModalVisibleAdd();
            // getBoxInfo();
          } else {
            message.error(data.msg);
          }
        })
        .catch((err) => {
          message.error("Interface error: scale_peeling");
        });
    }

    const handleCalibration = () => {
      let submitValue = {
        box_number: initData.box_number
      };
      httpPost("/api/v1/cabinet/box_calibration", JSON.stringify(submitValue))
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.code === 0) {
            message.success(`Calibration Success`)
            
            handleModalVisibleAdd();
            // getBoxInfo();
          } else {
            message.error(data.msg);
          }
        })
        .catch((err) => {
          message.error("Interface error: calibration");
        });
    }
    
    const handleOk = () => {
      form.validateFields((err, fieldsValue) => {
        if (err) return;
        form.resetFields();
       
        let submitValue = {
          ...fieldsValue
        };
        console.log(submitValue)
        httpPost("/api/v1/cabinet/set_cabinet_param", JSON.stringify(submitValue))
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (data.code === 0) {
              message.success("Success")
             
              handleModalVisibleAdd();
              // getBoxInfo();
            } else {
              message.error(data.msg);
            }
          })
          .catch((err) => {
            message.error("Interface error: set_cabinet_param");
          });
      });
    };
    return (
      <Modal
        destroyOnClose
        className="cali-modal"
        title={modalTitleAdd}
        visible={modalVisibleAdd}
        width={1050}
        okText="Calibration"
        onOk={handleOk}
        onCancel={() => handleModalVisibleAdd()}
        cancelButtonProps={{ style: { display: "none" } }}
        maskClosable={false}
        closable={this.state.closable}
        footer={[
          <Button
            type="primary"
            key="peel"
            onClick={() =>{ handlePeel() }}
          >
            Peel
          </Button>,
          <Button
            type="primary"
            key="calibration"
            onClick={() =>{ handleCalibration() }}
          >
            Calibration
          </Button>           
        ]}
      >
        <Form
          labelCol={{ span: 16 }}
          wrapperCol={{ span: 8 }}
          layout="horizontal"
          // {...formItemLayout}
          hideRequiredMark
        >
          <Row gutter={10}>
            <Col span={6}>
              <FormItem label="Device Number">
                {form.getFieldDecorator("device_number", {
                  
                  initialValue: initData.device_number,
                })(<InputNumber />)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Baud Rate">
                {form.getFieldDecorator("baud_rate", {
                  initialValue: initData.baud_rate,
                })(<InputNumber />)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Version H">
                {form.getFieldDecorator("version_h", {
                  initialValue: initData.version_h,
                })(<InputNumber />)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Version L">
                {form.getFieldDecorator("version_s", {
                  initialValue: initData.version_s,
                })(<InputNumber />)}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6}>
              <FormItem label="Farmar Weight">
                {form.getFieldDecorator("farmar_weight", {
                  initialValue: initData.farmar_weight,
                })(<InputNumber />)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Farmar ADC">
                {form.getFieldDecorator("farmar_adc", {
                  initialValue: initData.farmar_adc,
                })(<InputNumber />)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Tare Weight">
                {form.getFieldDecorator("tare_weight", {
                  initialValue: initData.tare_weight,
                })(<InputNumber />)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Weight Calc Param">
                {form.getFieldDecorator("weight_calc_param", {
                  initialValue: initData.weight_calc_param,
                })(<InputNumber />)}
              </FormItem>
            </Col>
          </Row>


          <Row gutter={16}>
            <Col span={6}>
              <FormItem label="Current Weight">
                {form.getFieldDecorator("current_weight", {
                  initialValue: initData.current_weight,
                })(<InputNumber />)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Current Weight ADC">
                {form.getFieldDecorator("current_weight_ADC", {
                  initialValue: initData.current_weight_adc,
                })(<InputNumber />)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Brightness Setting H16">
                {form.getFieldDecorator("brightness_setting_h16", {
                  initialValue: initData.brightness_setting_h16,
                })(<InputNumber />)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Brightness Setting L16">
                {form.getFieldDecorator("brightness_setting_l16", {
                  initialValue: initData.brightness_setting_l16,
                })(<InputNumber />)}
              </FormItem>
            </Col>
          </Row>
          
          
        </Form>
      </Modal>
    );
  }
}

const AddForm = Form.create()(AddFormContainer);
export default AddForm;
