import React from 'react';
import { withRouter} from 'react-router-dom';
import io from 'socket.io-client'

import { message  } from 'antd';
import { httpGet} from '../../http';
import Cabinet from './Cabinet';

import './home.css';

class Home extends React.Component {
  state = {
    socket: undefined,
    data: []
  }
  getUpdateBoxs = () => {
    const domain = document.domain
    let socket = ''
    if (domain === 'localhost') {
      socket = io("ws://192.168.0.136:9090")
    } else {
      socket = io("wss://" + document.location.host)
    }

    socket.on("UpdateBoxs", (data) => {
      if (data === "True") {
        this.getBoxsInfo()
      }
    })

    this.setState({
      socket: socket
    })
  }
  componentDidMount() {
    this.getBoxsInfo()
    setTimeout(() => {
      this.getUpdateBoxs()
    }, 0);
  }
  getBoxsInfo = () => {
    httpGet("/api/v1/cabinet/get_boxs_info")
    .then(res => {
      return res.json();
    })
    .then(data => {
      this.setState({
        data: data.data,
      })
    })
    .catch(err => {
      message.error('Interface error:get_boxs_info');
    }
    )
  }
  componentWillUnmount() {
    this.state.socket && this.state.socket !== '' && this.state.socket.close()
  }

  render() {
   
    return (
      <div className='home' key="home">
        <div className='wrapCabinet'>
          {this.state.data.map( (item, index) => {
            return <Cabinet key={ item.id } {...item}/>
          })}
        </div>
      </div>
    );
  };
 
};

export default withRouter(Home);