/**
 * Created by luosi on 2020/8/12.
 */
import React from 'react';
import { Table, Divider, Popconfirm, message, Icon } from 'antd';
import { httpGet, httpDelete } from '../../http';

import AddForm from './addForm';
import './user.css'


class UserList extends React.Component {
  state = {
    modalVisibleAdd: false,
    modalTitleAdd: "",
    editValues: {},
    data: [],
    allGroup: [],
  };

  componentDidMount() {
    this.getUserList();
  };
  getUserList = () => {
    httpGet("/api/v1/user/get_user_list")
      .then(res => {
        return res.json();
      })
      .then(data => {
        this.setState({
          data: data.data,
        })
      })
      .catch(err => {
        message.error('Interface error:get_user_list');
      }
      )
  };
  handleModalVisibleAdd = (flag, title, record) => {
    this.setState({
      modalVisibleAdd: !!flag,
      modalTitleAdd: title,
      editValues: record || {},
    });

  };
  delete_confirm = (id) => {
    httpDelete("/api/v1/user/delete_user", JSON.stringify({ user_id: id }))
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data.code === 0) {
          message.info('Delete Success!');
          this.getUserList();
        }
      })
      .catch(err => {
        message.error('Interface error:delete_user');
      }
      )
  };

  columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: <Icon type="plus-circle" style={{ fontSize: 22, cursor: 'pointer' }}
        onClick={() => this.handleModalVisibleAdd(true, 'Add User', {})}
      />,
      key: 'action',
      render: (text, record) => (
        <span>
          <Icon type="edit" onClick={() => this.handleModalVisibleAdd(true, 'Edit User', record)}/> 
          <Divider type="vertical" />
          <Popconfirm placement="top" title="Are you sure to delete this user?"
            onConfirm={() => this.delete_confirm(record.user_id)} okText="Yes" cancelText="No">
            <a href="#!;"><Icon type="delete" style={{ fontSize: 16, color: 'red' }} /></a>
          </Popconfirm>
        </span>
      ),
    },
  ];

  render() {
    const parentMethodsAdd = {
      handleModalVisibleAdd: this.handleModalVisibleAdd,
      getUserList: this.getUserList
    };
    return (
      <div className="userlist">
        <Table rowKey={record => record.user_id} columns={this.columns} dataSource={this.state.data} />
        <AddForm {...parentMethodsAdd} modalVisibleAdd={this.state.modalVisibleAdd} modalTitleAdd={this.state.modalTitleAdd}
          initData={this.state.editValues} allGroup={this.state.allGroup} />
      </div>
    )
  }
}

export default UserList;