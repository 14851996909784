import React, { Component } from "react";
import { Switch, Route, HashRouter } from "react-router-dom";
import Login from "components/Login/Login";
import SubRouter from "components/Router/SubRouter";
import ChangePassword from 'components/ChangePassword';

class Routers extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/change_password" component={ ChangePassword } />
          <Route path="/" component={SubRouter} />
        </Switch>
      </HashRouter>
    );
  }
}

export default Routers;
