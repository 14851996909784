import React from "react";
import { Modal, Input, Form, message } from "antd";

import { httpPost} from '../../http';

const FormItem = Form.Item;

class AddFormContainer extends React.Component {
  state = {
    data: [],
    searchValue: "",
    loading: false,
  };

  render() {
    const {
      modalVisibleAdd,
      form,
      modalTitleAdd,
      handleModalVisibleAdd,
      getSupplierList,
      initData,
    } = this.props;
    
    const handleOk = () => {
      form.validateFields((err, fieldsValue) => {
        if (err) return;
        form.resetFields();    

        let submitValue = {
          id: initData.id,
          ...fieldsValue
        };
        if (!initData.id) {
          httpPost("/api/v1/supplier/set_supplier_info", JSON.stringify(submitValue))
            .then(res => {
              return res.json();
            })
            .then(data => {
              if (data.code === 0) {
                handleModalVisibleAdd();
                getSupplierList();
              } else {
                message.error(data.msg);
              }
            })
            .catch(err => {
              message.error('Interface error: add_supplier');
            }
            )
        } else {
          httpPost("/api/v1/supplier/set_supplier_info", JSON.stringify(submitValue))
            .then(res => {
              return res.json();
            })
            .then(data => {
              if (data.code === 0) {
                handleModalVisibleAdd();
                getSupplierList();
              } else {
                message.error(data.msg);
              }
            })
            .catch(err => {
              message.error('Interface error:update_supplier');
            }
            )
        }
      });
    };
    return (
      <Modal
        destroyOnClose
        title={modalTitleAdd}
        visible={modalVisibleAdd}
        width={800}
        okText="Save"
        onOk={handleOk}
        onCancel={() => handleModalVisibleAdd()}
      >
        <Form
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 22 }}
          layout="horizontal"
          // {...formItemLayout}
          hideRequiredMark
        >
          <FormItem label="Name">
            {form.getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Please input the name of supplier.",
                },
              ],
              initialValue: initData.name,
            })(<Input />)}
          </FormItem>
          <FormItem label="Email">
            {form.getFieldDecorator("email", {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: "Please input email" 
                },
              ],
              initialValue: initData.email,
            })(<Input />)}
          </FormItem>
          
          <FormItem label="Address">
            {form.getFieldDecorator("address", {
              rules: [
                {
                  required: true,
                  message: "Please input the address of supplier.",
                },
              ],
              initialValue: initData.address,
            })(<Input />)}
          </FormItem>
          <FormItem label="Phone">
            {form.getFieldDecorator("phone", {
              rules: [
                {
                  required: true,
                  message: "Please input the phone of supplier.",
                },
              ],
              initialValue: initData.phone,
            })(<Input />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const AddForm = Form.create()(AddFormContainer);
export default AddForm;
