import React from 'react';

import {   Table, Icon, message} from 'antd';

import { httpGet} from '../../http';
import AddForm from './addForm';

import './alarm.css';

export default class Alarm extends React.Component {
  state = {
    modalVisibleAdd: false,
    modalTitleAdd: "",
    editValues: {},
    clinicInfo: {
      name: "name",
      address:  "address"
    },
    data: []
  }

  handleModalVisibleAdd = (flag, title, record) => {
    this.setState({
      modalVisibleAdd: !!flag,
      modalTitleAdd: title,
      editValues: record || {},
    });

  };
  getAlarmList = () => {
    httpGet("/api/v1/medicine/get_alarms")
    .then(res => {
      return res.json();
    })
    .then(data => {
      this.setState({
        data: data.data,
      })
    })
    .catch(err => {
      message.error('Interface error: get_alarm_list');
    }
    )
  }

  getClinicInfo = () => {
    httpGet("/api/v1/hospital/get_hospital_info")
    .then(res => {
      return res.json();
    })
    .then(data => {
      this.setState({
        clinicInfo: data.data,
      })
    })
    .catch(err => {
      message.error('Interface error:get_hospital_info');
    }
    )
  }
  
  componentDidMount() {
    this.getAlarmList()
    this.getClinicInfo()
  }

  componentWillUnmount() {
  }

  columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: 'center'
    },
    {
      title: "Medicine",
      dataIndex: "medicine",
      key: "medicine",
      align: 'center'
    },
    {
      title: "Store Num",
      dataIndex: "store_num",
      key: "store_num",
      align: 'center'
    },
    {
      title: "Default Order Num",
      dataIndex: "default_order_num",
      key: "default_num",
      align: 'center'
    },
    {
      title: "Supplier",
      dataIndex: "supplier_name",
      key: "supplier_name",
      align: 'center'
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email",
      align: 'center'
    }, 
    {
      title: "phone",
      dataIndex: "phone",
      key: "phone",
      align: 'center'
    }, 
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <span>
          <Icon type="mail" onClick={() => this.handleModalVisibleAdd(true, 'Email', record)}/> 
        </span>
      ),
    },
  ]
  render() {
    const parentMethodsAdd = {
      handleModalVisibleAdd: this.handleModalVisibleAdd,
      getAlarmList: this.getAlarmList
    };
   
    return (
      <div className='medicine'>
        <Table
            rowKey={(record, index) => index}
            columns={this.columns}
            dataSource={this.state.data}
          />
        <AddForm {...parentMethodsAdd} modalVisibleAdd={this.state.modalVisibleAdd} modalTitleAdd={this.state.modalTitleAdd} initData={this.state.editValues} clinicInfo={this.state.clinicInfo}/>
      </div>
    );
  };
 
};
