import React from "react";
import { Modal, Input, Form, Select, message, InputNumber, Button } from "antd";

import { httpPost} from '../../http';

const FormItem = Form.Item;
const { Option } = Select;

class AddFormContainer extends React.Component {
  state = {
    data: [],
    searchValue: "",
    loading: false,
    handleSelect: false
  };

  render() {
    const {
      modalVisibleAdd,
      getBoxsInfo,
      form,
      modalTitleAdd,
      handleModalVisibleAdd,
      medicineData,
      initData,
    } = this.props;
    
    const getMedicineID = (medicine) => {
      const newArray = medicineData.filter((item) => {
        return item.medicine === medicine
      })
      return newArray.length > 0 ? newArray[0].id : undefined
    }
    const handleOk = () => {
      form.validateFields((err, fieldsValue) => {
        if (err) return;
        form.resetFields();
        let submitValue = {
          ...fieldsValue,
          medicine_id: getMedicineID(fieldsValue.medicine)
        };
        httpPost("/api/v1/cabinet/set_box", JSON.stringify(submitValue))
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (data.code === 0) {
              handleModalVisibleAdd();
              getBoxsInfo();
              this.setState({
                handleSelect: false
              })
            } else {
              message.error(data.msg);
            }
          })
          .catch((err) => {
            message.error("Interface error:update_box");
          });
      });
    };
    const handleChange = () => {
      this.setState({
        handleSelect: true
      })
    }
    const resetBox = () => {
      let submitValue = {
        box_number: initData && initData.box_number
      }
      httpPost("/api/v1/cabinet/reset_box", JSON.stringify(submitValue))
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (data.code === 0) {
              handleModalVisibleAdd();
              getBoxsInfo();
              this.setState({
                handleSelect: false
              })
            } else {
              message.error(data.msg);
            }
          })
          .catch((err) => {
            message.error("Interface error: reset_box");
          });
    }
    return (
      <Modal
        destroyOnClose
        title={modalTitleAdd}
        visible={modalVisibleAdd}
        width={500}
        onCancel={() => { 
            this.setState({
              handleSelect: false
            })
            handleModalVisibleAdd()
          }
        }
        footer={
          [
            <Button key="cancle" onClick={() => {
              handleModalVisibleAdd(false, '', initData)
              this.setState({handleSelect: false})
            }}>Cancel</Button>,
            <Button key="reset" type="danger" onClick={() => {
              resetBox()
            }}>Reset</Button>,
            <Button key="save" type="primary" onClick={handleOk}>Save</Button>,
            
          ]
        }
      >
        <Form
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 12 }}
          layout="horizontal"
          // {...formItemLayout}
          hideRequiredMark
        >
          <FormItem label="NO">
            {form.getFieldDecorator("box_number", {
              rules: [
                {
                  required: true,
                  message: "Please input medicine.",
                },
              ],
              initialValue: initData.box_number,
            })(<Input disabled />)}
          </FormItem>
          <FormItem label="Medicine">
            {form.getFieldDecorator("medicine", {
              rules: [
                {
                  required: true,
                  message: "Please input medicine.",
                },
              ],
              initialValue: initData.medicine,
            })(
              <Select
                onChange={handleChange}>
                {medicineData.map((item) => (
                  <Option key={item.medicine} value={item.medicine}>
                    {item.medicine}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
          <FormItem label="Total" style={{display: this.state.handleSelect ? '' : 'none'}}>
            {form.getFieldDecorator("total", {
              initialValue: initData.total === 0 ? 1: initData.total,
            })(<InputNumber />)}
          </FormItem>
          <FormItem label="Unit">
            {form.getFieldDecorator("unit", {
              rules: [
                {
                  required: true,
                  message: "Please input the unit of medicine.",
                },
              ],
              initialValue: initData.unit,
            })(
              <Select>
                <Option key="Box" value="Box">Box</Option>
                <Option key="Bottle" value="Bottle">Bottle</Option>
                <Option key="Strip" value="Strip">Strip</Option>
                <Option key="Packet" value="Packet">Packet</Option>
                <Option key="Tubes" value="Tubes">Tubes</Option>
              </Select>
            )}
          </FormItem>
          <FormItem label="Low Level">
            {form.getFieldDecorator("low_level", {
              initialValue: initData.low_level,
            })(<InputNumber />)}
          </FormItem>
          <FormItem label="Normal Level">
            {form.getFieldDecorator("normal_level", {
              initialValue: initData.normal_level,
            })(<InputNumber />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const AddForm = Form.create()(AddFormContainer);
export default AddForm;
