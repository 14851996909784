import React from 'react';

import {  message, Table, } from 'antd';

import { httpGet } from '../../http';
import { formatDate } from 'lib/formatDate';

import './exception.css';

const sourceDateFormat = 'YYYY-MM-DD HH:mm:ss'
const localDateFormat = 'DD/MM/YYYY HH:mm'

export default class Exception extends React.Component {
  state = {
    modalVisibleAdd: false,
    modalTitleAdd: "",
    editValues: {},
    data: [
    ]
  }

  handleModalVisibleAdd = (flag, title, record) => {
    this.setState({
      modalVisibleAdd: !!flag,
      modalTitleAdd: title,
      editValues: record || {},
    });

  };
  
  getExceptionList = () => {
    httpGet("/api/v1/exception/get_exceptions")
    .then(res => {
      return res.json();
    })
    .then(data => {
      this.setState({
        data: data.data,
      })
    })
    .catch(err => {
      message.error('Interface error: get_exception_list');
    }
    )
  }
  // delete_confirm = (id) => {
  //   httpDelete("/api/v1/exception/delete_exception", JSON.stringify({ id: id }))
  //     .then(res => {
  //       return res.json();
  //     })
  //     .then(data => {
  //       if (data.code === 0) {
  //         message.info('Delete Success!');
  //         this.getExceptionList();
  //       }
  //     })
  //     .catch(err => {
  //       message.error('Interface error:delete_exception');
  //     }
  //     )
  // };
  componentDidMount() {
    this.getExceptionList()
  }

  componentWillUnmount() {
  }

  columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: 'center'
    },
    {
      title: "Medicine",
      dataIndex: "medicine",
      key: "medicine",
      align: 'center'
    },
    {
      title: "Record",
      dataIndex: "record",
      key: "record",
      align: 'center'
    },
    {
      title: "Time",
      dataIndex: "updated_time",
      key: "updated_time",
      align: 'center',
      render: (text, record) => {
        const date = formatDate(record.updated_time, sourceDateFormat, localDateFormat)
        return <span>{date}</span>
      }
    },
    
    // {
    //   title: "Action",
    //   key: 'action',
    //   align: 'center',
    //   render: (text, record) => (
    //     <span>
    //       <Icon type="edit" onClick={() => this.handleModalVisibleAdd(true, 'Edit Exception', record)}/> 
    //       <Divider type="vertical" /> 
    //       <Popconfirm placement="top" title="Are you sure you want to delete this exception?"
    //         onConfirm={() => this.delete_confirm(record.id)} okText="Yes" cancelText="No" >
    //         <a href="#!;"><Icon type="delete" style={{ fontSize: 16, color: 'red' }} /></a>
    //       </Popconfirm>
    //     </span>
    //   ),
    // },
  ]
  render() {
    return (
      <div className='exception'>
        <Table
            rowKey={(record, index) => index}
            columns={this.columns}
            dataSource={this.state.data}
          />
      </div>
    );
  };
 
};
