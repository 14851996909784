import React from "react";
import { Modal, message } from "antd";

import { httpPost} from '../../http';

export default class AddForm extends React.Component {
  state = {
    data: [],
  };

  

  componentDidMount() {
    
  }
  render() {
    const {
      modalVisibleAdd,
      modalTitleAdd,
      handleModalVisibleAdd,   
      initData,
      clinicInfo,
      getAlarmList
    } = this.props;
    
    const handleOk = () => {
      const submitValue = {
        id: initData.id
      }
      httpPost("/api/v1/medicine/send_alarm", JSON.stringify(submitValue))
        .then(res => {
          return res.json();
        })
        .then(data => {
          if (data.code === 0) {
            handleModalVisibleAdd();
            getAlarmList()
          } else {
            message.error(data.msg);
          }
        })
        .catch(err => {
          message.error('Interface error: send email');
        }
        )
          
    };
    return (
      <Modal
        destroyOnClose
        title={modalTitleAdd}
        visible={modalVisibleAdd}
        width={800}
        okText="Send"
        onOk={handleOk}
        onCancel={() => handleModalVisibleAdd()}
      >
        
        <div>Dear {initData.supplier_name}, </div>
        <div>&nbsp;&nbsp;We'd like to order {initData.default_order_num} {initData.unit}s of {initData.medicine}</div>
        <br />
        <div>{clinicInfo.name}</div>
        <div>Address: {clinicInfo.address}</div>
      </Modal>
    );
  }
}
