import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Icon, Avatar, Dropdown, message } from "antd";
import { httpPost } from "http/index.js";

import "style/Outline.scss";
import "style/common.css";

const { Header, Content, Footer, Sider } = Layout;

class LeftSider extends Component {
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  render() {
    const role = sessionStorage.getItem("role");
    let menu = <div></div>;

    switch (role) {
      case "nurse":
        menu = (
          <Menu theme="dark" defaultSelectedKeys={["/"]} mode="inline">
            <Menu.Item key="Home">
              <Link to="/home">
                <Icon type="home" />
                <span>Dashboard</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="Pharmacist">
              <Link to="/pharmacist">
                <Icon type="table" />
                <span>Dispensing</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="Exception">
              <Link to="/exception">
                <Icon type="warning" />
                <span>Exception</span>
              </Link>
            </Menu.Item>
          </Menu>
        )
        break;
      case "doctor":
        menu = (
          <Menu theme="dark" defaultSelectedKeys={["/"]} mode="inline">
            <Menu.Item key="Home">
              <Link to="/doctor">
                <Icon type="highlight" />
                <span>Doctor</span>
              </Link>
            </Menu.Item>
          </Menu>
        )
        break;
      case "admin" :
        menu = (
          <Menu theme="dark" defaultSelectedKeys={["/"]} mode="inline">
            <Menu.Item key="Home">
              <Link to="/home">
                <Icon type="home" />
                <span>Dashboard</span>
              </Link>
            </Menu.Item>
              <Menu.Item key="Setting">
              <Link to="/setting">
                <Icon type="setting" />
                <span>Setting</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="Alarm">
              <Link to="/alarm">
                <Icon type="alert" />
                <span>Alarm</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="Supplier">
              <Link to="/supplier">
                <Icon type="dropbox" />
                <span>Supplier</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="Medicine">
              <Link to="/medicine">
                <Icon type="medicine-box" />
                <span>Medicine</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="Exception">
              <Link to="/exception">
                <Icon type="warning" />
                <span>Exception</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="User">
              <Link to="/user">
                <Icon type="user" />
                <span>User</span>
              </Link>
            </Menu.Item>
          </Menu>
        )
        break; 
      default:
        menu = (
          <Menu theme="dark" defaultSelectedKeys={["/"]} mode="inline">
            <Menu.Item key="Home">
              <Link to="/home">
                <Icon type="home" />
                <span>Dashboard</span>
              </Link>
            </Menu.Item>
            
        </Menu>
        )
    }
    return (
      <Sider
        collapsible
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
      >
        <div className="logo">
          <h1 className={this.state.collapsed ? "small" : "medimu"}>Smart Cabinet</h1>
        </div>

        {menu}
      </Sider>
    );
  }
}

class GeneralHeader extends Component {
  logout = () => {
    httpPost("/auth/logout")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        if (data.code === 0) {
          sessionStorage.setItem("isLogin", "0");
          window.location.reload();
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  render() {
    const menu = (
      <Menu onClick={this.logout}>
        <Menu.Item key="1">
          <Icon type="user" />
          Log out
        </Menu.Item>
      </Menu>
    );

    return (
      <Header>
        <div className="user">
          <div id="components-dropdown-demo-dropdown-button">
            <Dropdown overlay={menu} placement="bottomLeft">
              <Avatar className="user_header" size={48}>
                {sessionStorage.getItem("username")
                  ? sessionStorage.getItem("username")[0].toUpperCase()
                  : ""}
              </Avatar>
            </Dropdown>
          </div>
        </div>
      </Header>
    );
  }
}

class Outline extends Component {
  render() {
    return (
      <div className="customize">
        <Layout style={{ minHeight: "100vh" }}>
          <LeftSider></LeftSider>
          <Layout>
            <GeneralHeader />

            <Content className="content">{this.props.children}</Content>

            <Footer>Smart Cabinet ©2022 Created by Nextan</Footer>
          </Layout>
        </Layout>
      </div>
    );
  }
}

export default Outline;
