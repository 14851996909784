import React from 'react';

import {  Divider, Popconfirm, message, Table, Icon} from 'antd';

import { httpGet, httpDelete} from '../../http';
import AddForm from './addForm';

import './supplier.css';

export default class Supplier extends React.Component {
  state = {
    modalVisibleAdd: false,
    modalTitleAdd: "",
    editValues: {},
    data: []
  }

  handleModalVisibleAdd = (flag, title, record) => {
    this.setState({
      modalVisibleAdd: !!flag,
      modalTitleAdd: title,
      editValues: record || {},
    });

  };
  
  getSupplierList = () => {
    httpGet("/api/v1/supplier/get_suppliers_info")
    .then(res => {
      return res.json();
    })
    .then(data => {
      this.setState({
        data: data.data,
      })
    })
    .catch(err => {
      message.error('Interface error: get_supplier_list');
    }
    )
  }
  delete_confirm = (id) => {
    httpDelete("/api/v1/supplier/delete_supplier", JSON.stringify({ id: id }))
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data.code === 0) {
          message.info('Delete Success!');
          this.getSupplierList();
        }
      })
      .catch(err => {
        message.error('Interface error:delete_supplier');
      }
      )
  };
  componentDidMount() {
    this.getSupplierList()
  }

  componentWillUnmount() {
  }

  columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: 'center'
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: 'center'
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: 'center'
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      align: 'center'
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      align: 'center'
    }, 
    {
      title: <Icon type="plus-circle" style={{ fontSize: 22, cursor: 'pointer' }}
        onClick={() => this.handleModalVisibleAdd(true, 'Add Supplier', {})}
      />,
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <span>
          <Icon type="edit" onClick={() => this.handleModalVisibleAdd(true, 'Edit Supplier', record)}/> 
          <Divider type="vertical" /> 
          <Popconfirm placement="top" title="Are you sure you want to delete this supplier?"
            onConfirm={() => this.delete_confirm(record.id)} okText="Yes" cancelText="No" >
            <a href="#!;"><Icon type="delete" style={{ fontSize: 16, color: 'red' }} /></a>
          </Popconfirm>
        </span>
      ),
    },
  ]
  render() {
    const parentMethodsAdd = {
      handleModalVisibleAdd: this.handleModalVisibleAdd,
      getSupplierList: this.getSupplierList
    };
   
    return (
      <div className='supplier'>
        <Table
            rowKey={(record, index) => index}
            columns={this.columns}
            dataSource={this.state.data}
          />
        <AddForm {...parentMethodsAdd} modalVisibleAdd={this.state.modalVisibleAdd} modalTitleAdd={this.state.modalTitleAdd} initData={this.state.editValues} />
      </div>
    );
  };
 
};
