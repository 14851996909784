import React from 'react';

import {  message, Table, Icon, Modal, Spin} from 'antd';

import { httpGet, httpPost} from '../../http';
import AddForm from './addForm';

import './calibration.css';

export default class Calibration3 extends React.Component {
  state = {
    spinVisible: false,
    modalVisibleAdd: false,
    modalTitleAdd: "",
    editValues: {},
    data: []
  }

  init = () => {
    this.setState({
      spinVisible: false,
      interval: null
    });
  };

  handleModalVisibleAdd = (flag, title, record) => {
    this.setState({
      modalVisibleAdd: !!flag,
      modalTitleAdd: title,
      editValues: record || {},
    });

  };
  
  getBoxInfo = () => {
    httpGet("/api/v1/cabinet/get_boxs_info")
    .then(res => {
      return res.json();
    })
    .then(data => {
      this.setState({
        data: data.data,
      })
    })
    .catch(err => {
      message.error('Interface error: get_boxs_info');
    }
    )
  }

  checkTaskID = (id) => {
    httpGet("/api/v1/cabinet/conf/result/" + id)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.code === 0) {  
          const _data = data.data
          if (_data.state === "SUCCESS") {     
            clearInterval(this.state.interval);
            const record = _data.conf
            this.handleModalVisibleAdd(true, 'Calibrate Boxs', record)
            this.init()
          }
        } 
        return
      })
      .catch((err) => {
        message.error("Interface error: result task", err);
      });
  }

  getHardwareParam = (box_number) => {
    let submitValue = {
      box_number: box_number
    };
    console.log(submitValue)
    httpPost("/api/v1/cabinet/request_box_configuration", JSON.stringify(submitValue))
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.code === 0) {

          // 开启遮罩
          this.setState({
            spinVisible: true
          })

            // 异步查询任务
          console.log("taskID", data.data)
          const interval = setInterval(() => {
            this.checkTaskID(data.data)
          }, 1 * 1000)

          this.setState({
            interval: interval,
          })
          // handleModalVisibleAdd();
          // getBoxInfo();
        } else {
          message.error(data.msg);
        }
      })
      .catch((err) => {
        message.error("Interface error: set_cabinet_param");
    });
  }

  componentDidMount() {
    this.getBoxInfo()
  }

  componentWillUnmount() {
  }

  columns = [
    {
      title: "ID",
      dataIndex: "box_number",
      key: "box_number",
      align: 'center'
    },
    {
      title: "Medicine",
      dataIndex: "medicine",
      key: "medicine",
      align: 'center'
    },
   
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <span>
          <Icon type="edit" onClick={() => this.getHardwareParam(record.box_number)}/> 
        </span>
      ),
    },
  ]
  render() {
    const parentMethodsAdd = {
      handleModalVisibleAdd: this.handleModalVisibleAdd,
      getBoxInfo: this.getBoxInfo
    };
   
    return (
      <div className='calibration' key="calibration">
        <div >
          <Table
              rowKey={(record, index) => index}
              columns={this.columns}
              dataSource={this.state.data}
              pagination={false}
            />
        </div>
        <AddForm {...parentMethodsAdd} modalVisibleAdd={this.state.modalVisibleAdd} modalTitleAdd={this.state.modalTitleAdd} initData={this.state.editValues} />
        {/* <Spin size="large"/> */}
        <Modal
          wrapClassName="spinModal"
          style={{marginTop: "180px"}}
          title={null}
          visible={this.state.spinVisible} 
          footer={null}
          closable={false}   
          maskClosable={false} 
        >
          <div style={{textAlign: "center"}}>
            <Spin size="large"/>
          </div>
        </Modal>
      </div>
    );
  };
 
};
