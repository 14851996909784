import React, { Component } from "react";
import { Modal, Button, message } from "antd";

import { httpGet } from '../../http';


export default class View extends Component {
  state = {
    opened: false,
    closable: true,
    status: '',
    taskID: '',
    errorList: [],
  };

  init = () => {
    this.setState({
      opened: false,
      closable: true,
      status: '',
      taskID: '',
      errorList: []
    });
  };
  render() {
    const { modalVisible, handleModalVisible, getPatientsList, initData } = this.props;
    
    const checkTaskID = (id) => {
      httpGet("/api/v1/patient/result/" + id)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.code === 0) {          
            if (data.data.state === "SUCCESS") {
              clearInterval(this.state.interval);
              this.setState({
                status: data.data.failed_info.length > 0 ? "ERROR" : "SUCCESS",
                errorList: data.data.failed_info,
                closable: true
              })
            }
          } 
          return
        })
        .catch((err) => {
          message.error("Interface error: result task", err);
        });
    }

    const openCabinet = (item) => {
      this.setState({
        opened: true,
        closable: false
      });
      console.log("opentCabinet", item);
      httpGet("/api/v1/patient/get_patient_pharmaceuticals?id=" + item.id)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.code === 0) {
            getPatientsList()

            this.setState({
              status: 'PROGRESS',
              taskID: data.data
            })
            const interval = setInterval(() => {
              checkTaskID(this.state.taskID)
            }, 5 * 1000)

            this.setState({
              interval: interval
            })

            message.success("Open Cabinet");
          } else {
            message.error(data.msg);
          }
        })
        .catch((err) => {
          message.error("Interface error: get_patient_pharmaceuticals");
        });
    };

    return (
      <div>
        <Modal
          destroyOnClose
          className="view-modal"
          title={initData.name}
          visible={modalVisible}
          onCancel={() => handleModalVisible()}
          cancelButtonProps={{ style: { display: "none" } }}
          maskClosable={false}
          closable={this.state.closable}
          afterClose={() => this.init()}
          footer={[
            <Button
              type="primary"
              key="get"
              disabled={this.state.opened}
              onClick={() => openCabinet(initData)}
            >
              Pick Up
            </Button>        
          ]}
        >
          {/* {initData.medicine_list &&
            initData.medicine_list.map((item, index) => {
              let medicine = "";
              let num = 0;
              for (let key in item) {
                medicine = key;
                num = item[medicine];
              }
              return (
                <p key={index}>
                  {index + 1}. {medicine} x {num}
                </p>
              );
            })} */}
            {
              initData.medicine_list && Object.keys(initData.medicine_list).map( (key, index) => {
                return (
                <p key={key}>
                  {index + 1}. {key} x {initData.medicine_list[key]}
                </p>
              )
              })
            }
            <br/><br/><br/>
            <div>Status: {this.state.status}</div>
            <div>
              {
                this.state.errorList && this.state.errorList.map( (item, index) => {
                  let medicine = "";
                  let msg = "";
                  for (let key in item) {
                    medicine = key;
                    msg = item[medicine];
                  }
                  return (
                    <div key={index} style={{color: 'red'}}>
                      {index + 1}. {medicine} {msg}
                    </div>
                  )
                })
              }
            </div>
        </Modal>
      </div>
    );
  }
}
